import { CallToAction } from '../sections/call-to-action';
import { Faqs } from '../sections/faqs';
import { Hero } from '../sections/hero';
import { Pricing } from '../sections/pricing';
import { PrimaryFeatures } from '../sections/primary-features';
import { Reviews } from '../sections/reviews';
import { SecondaryFeatures } from '../sections/secondary-features';

export default function Index() {
  return (
    <>
      <Hero />
      <PrimaryFeatures />
      <SecondaryFeatures />
      {/* <CallToAction />
      <Reviews />
      <Pricing />
      <Faqs /> */}
    </>
  );
}
